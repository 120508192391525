/* eslint-disable react/prop-types, no-use-before-define */
import { graphql } from 'gatsby';
import React from 'react';

import MainLayout from 'layouts/main';
import getReactContentWithLazyBlocks from 'utils/get-react-content-with-lazy-blocks';

const Page = ({
  data: {
    wpPage: {
      content,
      pageFields: { headerTheme },
      seo,
    },
  },
  pageContext,
}) => {
  const { reactedContent } = getReactContentWithLazyBlocks(content);
  return (
    <MainLayout seo={seo} pageContext={pageContext} headerTheme={headerTheme}>
      {reactedContent}
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      pageFields {
        headerTheme
      }
      ...wpPageSeo
    }
  }
`;

export default Page;
